import TradingViewWidget, { Themes } from "react-tradingview-widget";

export const TradingGraph = ({ tokenSymbol }) => {

    const getTradingViewSymbol = (tokenSymbol) => {
        const tokenName = ["DINGERWETH_21A7AF", "YDFWETH_153F20", "NOIAWETH_B8A1A8", "CPOOLWETH_99920E", "BITGWETH_1C4D21", "FISWETH_6DA6C5", "BLOCKSWETH_DAC9B2", "YGGWETH_99B42F", "WHALEWETH_229B8D.USD", "STOSWETH_80D972", "UWUWETH_98C588.USD", "WALVWETH_BE1737 ", "NEARUSD", " HRDWETH_EEBBCE", "RSRWETH_32D925.USD", "PUNKWETH_33D1CF.USD", "HETHWETH_4F2CBF.USD", " WLITIWETH_5AC9EA", "NTXWETH_B3D994", "PIRATEWETH_03A81C", "BENDWETH_336EF4", "SIPHERWETH_F3FDCF", "EMAIDUSDC_355938.USD", "YGGWETH_99B42F.USD", "SIDUSWETH_8C07E1", "RVSTWETH_649082.USD", "OPULUSDC_A5F0CF", "PYUSDUSDC_D3F5C3.USD", "PADREWETH_3B8F9B", "BMDAWETH_43B82C", "VXVWETH_CBBC98.USD", "KETWETH_5A012D", "LOOTERWETH_FDA70E", "X7DAOWETH_75311E", "X7RWETH_613924", "CVXCRV_645C3A.USD", "SDAOWETH_424485.USD", "ETHPADWETH_04B6BE", "ZENTUSDT_135A41", "NEURALWETH_111295.USD", "OXWETH_AB72F4", "DOGWIFHATWETH_11C20A", "DOLAUSDC_845F28", "PUFETHWETH_BDB04E.USD", "RSWETHWETH_C41057.USD", "SAVMWETH_AD9EF1.USD", "USDAEURA_580EE6.USD", "FOXGIV_AD0E10.USD", " RSETHWETH_059615.USD", "EZETHWSTETH_1B9D58.USD", "SUSDEWSTETH_8609B6.USD", " OSETHUSDC_C2A679.USD", "TOKENWETH_C7E6B6", "METHWETH_047080.USD", "SAFEREUMWETH_3681A3", "GAINSWETH_D3719A", "UNIV2VOW_F848E9", "ARKMWETH_9CB91E.USD", "AEGWETH_419E84.USD", "WZNNWETH_DAC866.USD", "PLEBWETH_C3A03B", "AMPRETHWETH_D8ED2C.USD", "WOJAKWETH_4EC8BA", "SWETHWETH_30EA22.USD", "AETHIRWETH_976C6F", "AAVEBANKWETH_34A9E7.USD", "ZARPUSDC_17A155.USD", "SDAIWETH_422A29.USD", "THRYUSDC_5F558B", "AAVEUSDC_DCEAF5.USD", "BLURWETH_2BFDB9.USD", "DFIUSDT_9E251D", "FXSFRAX_E1573B", "DOGUSDT_3D6D0E", "AURAWETH_6C350E", "VDZWETH_661ECC", "COMPWETH_CFFDDE", "GHSTWETH_873D3C", "AAVEWETH_2ACEDA.USD", "AAVEWETH_DFC14D", "AAVEBANKWETH_34A9E7.USD", "GHSTWETH_873D3C"];
        const token = ["DINGER", "YDF", "NOIA", "CPOOL", "BTBS", "rETH_2", "BLOCKS", "YGG", "WHALE", "STOS", "uWu", "wALV", "Near", "HRD", "RSR", "PUNK", "HORD", "wLITI", "NTX", "NATION", "BEND", "SIPHER", " eMAID", "GUILD", "SIDUS", "RVST", "OPUL", "PYUSD", "PAD", "BMDA", "VXV", "RYOSHI", "LOOT", "X7DAO", "X7R", "cvxCRV", "SDAO", "ETHPAD", "ZENT", "NEURA", "OX", "DOGWIFHAT", "Staked Dola", "PufETH", "rswETH", "SAVM", " USDA", "genETH", " RSETH", "ezETH", "sUSDe", "osETH", "TOKEN", "METH", "SAFEREUM", "Gains", "8PAY v2 ", "ARKM", "aEthENS", "wZNN", "PLEB", "wBETH", "WOJAK", "SWETH", "aETHMKR", "aEthUNI", "ZARP", "sDAI", "PEEP$ ", "aEthCRV", "BLUR", "DFI_2", " SFRXETH", "XAUt=Tether Gold", " auraBAL", "VCASH", " CUSDCv3", "aETHGHO", "aEthPYUSD", "aETHLDO", "aETHSNX", "aETHBAL"]; let b = ["DINGERWETH_21A7AF", "YDFWETH_153F20", "NOIAWETH_B8A1A8", "CPOOLWETH_99920E", "BITGWETH_1C4D21", "FISWETH_6DA6C5", "BLOCKSWETH_DAC9B2", "YGGWETH_99B42F", "WHALEWETH_229B8D.USD", "STOSWETH_80D972", "UWUWETH_98C588.USD"];

        let inx = token.indexOf(tokenSymbol)
        if (inx > -1) {
            return tokenName[inx]
        } else {
            return `${tokenSymbol}/WETH`;
        }
    };

  return (
    <TradingViewWidget
      symbol={getTradingViewSymbol(tokenSymbol)}
      theme={Themes.DARK}
      withdateranges={true}
      hide_side_toolbar={false}
      allow_symbol_change={true}
      locale="en"
      autosize
      interval="D"
      timezone="Etc/UTC"
      style="1"
      calendar={false}
    />
  );
};
