import React, { useContext, useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png";
import { OverLayAddressTooltip } from "../../common/Functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { pineappleDexService } from "../../service/api.service";
import swal from "sweetalert";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { formatWalletAddress } from "../../config/config";
import { GlobalContext } from "../../globalState/GlobalContext";


export const NotificationDetail = () => {
    const navigate = useNavigate();
    let params = useParams();
    let swapId = params && params.id ? params.id : ''
    const globalStates = useContext(GlobalContext);
    const location = useLocation();
    const receivedData = location.state;
    console.log("receivedData", receivedData)

    const [loader, setLoader] = useState(false);

    const [orderDetail, setOrderDetail] = useState(null);
    const [copiedUserAddress, setCopiedUserAddress] = useState(false);
    const [copiedValue, setCopiedValue] = useState(null);
    const [copiedfromValue, setCopiedfromValue] = useState(null);
    const [copied, setCopied] = useState(false);
    const [copiedFrom, setCopiedFrom] = useState(false)
    const [copiedTo, setCopiedTo] = useState(false)
    const [copiedHash, setCopiedHash] = useState(false);
    const [query, set_query] = useSearchParams();
    const id = query.get('id');
    const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState('');
    const [fromCopiedValue, setFromCopiedValue] = useState(null);
    const [toCopiedValue, setToCopied] = useState(null);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [copiedFromAddress, setCopyFromAddress] = useState(false);
    const [copiedToAddress, setCopyToAddress] = useState(false);
    const [copiedHashAddress, setCopiedHashAddress] = useState(false);
    const [copiedHashValue, setCopiedHashValue] = useState(null);
    const [copiedFromValue, setCopiedFromValue] = useState(null);
    const [copiedToValue, setCopiedToValue] = useState(null);



    useEffect(() => {
        setLoader(true);
        getOrderDetail();
    }, []);


    const getOrderDetail = async () => {
        try {
            let params = {
                userId: receivedData?.userId
            }
            const response = await pineappleDexService.getNotificationDeatil(params);
            if (response?.status == 200) {
                console.log("notifation getOrderDetail------->>", response?.data?.data)
                let data = response.data && response.data.data ? response.data.data : null;
                setOrderDetail(data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };


    async function handleMarkAsResolved(alertId, status) {
        const params = {
            id: alertId,
            all: status
        }
        // setLoader(true);
        try {
            const response = await pineappleDexService.resolveNotification(params);
            if (response.status === 200) {
                swal({ icon: "success", text: "Notification status updated successfully.", button: "OK" });
                globalStates.setManagenotification(!globalStates.managenotification);
                getOrderDetail();
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoader(false);
        }
    }
    function handleApiError(error) {
        setLoader(false);
        if (error?.response?.status === 401) {
            swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                localStorage.clear();
                window.location.href = "/";
            });
        } else {
            swal({
                icon: 'error',
                text: error?.response?.data?.message || error?.message,
                button: "OK"
            });
        }
    }

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'HIGH':
                return 'priority-high';
            case 'MEDIUM':
                return 'priority-medium';
            case 'LOW':
                return 'priority-low';
            default:
                return '';
        }
    };

    const handleCopy = (index, walletAddress) => {
        setCopiedIndex(index); // Current index save kar rahe hain copied ke liye
        setTimeout(() => {
            setCopiedIndex(null); // 2 seconds ke baad copied message ko remove karenge
        }, 2000);
    };


    const handleTransactionDataCopy = (address, type) => {
        if (type === "fromToken") {
            setCopyFromAddress(true);
            setCopiedFromValue(address);
            setTimeout(() => setCopyFromAddress(false), 2000);
        }
        else if (type === 'toAddress') {
            setCopyToAddress(true);
            setCopiedToValue(address);
            setTimeout(() => setCopyToAddress(false), 2000);
        }

        else if (type === 'hashAddress') {
            setCopiedHash(true);
            setCopiedHashValue(address);
            setTimeout(() => setCopiedHash(false), 2000);
        }
    };




    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between" >
                                    <Col md={12} className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="back-btn-main me-4" onClick={() => navigate("/notification-security", { state: "from_swap_detail_screen" })}>
                                                <i className="fa-solid fa-arrow-left me-2"></i>
                                                Back
                                            </div>
                                            <h2 className="align-self-center m-0 inner-details-heading">Notification Detail</h2>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12}>
                                <div className="transaction-swap-details">
                                    <div className="wallet-address-body">
                                        {
                                            loader ?
                                                (
                                                    <>
                                                        {[...Array(4)].map((_, index) => (
                                                            <div className="wallet-info" key={index}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <ShimmerThumbnail />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                                :

                                                <>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Alert Title</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{orderDetail?.eventAlert?.subject || "N/A"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Alert Description</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.eventAlert?.message || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Alert Type</label>
                                                                <div>
                                                                    <span className="toekn-main-paragraph">{orderDetail?.eventAlert?.eventCategorizationId?.eventType || "N/A"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Priority</label>
                                                                <div className={getPriorityClass(orderDetail?.eventAlert?.eventCategorizationId?.priority)} >
                                                                    {orderDetail?.eventAlert?.eventCategorizationId?.priority || 'N/A'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">




                                                            <div className="col-md-6">
                                                                <label>Date & Time</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.eventAlert?.createdAt ? moment(orderDetail?.eventAlert?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>
                                                                    <>
                                                                        {orderDetail?.eventAlert?.eventCategorizationId?.eventType == "API_KEY_USAGE" ? "API Usage Range" : orderDetail?.eventAlert?.eventCategorizationId?.eventType == "TRANSACTIONS_FAILURES" ? "Transaction Failed Range" : "User Range"}
                                                                        {orderDetail?.eventAlert?.eventCategorizationId?.eventType !== "API_KEY_USAGE" ?
                                                                            orderDetail?.eventAlert?.eventCategorizationId?.eventType === "HIGH_TRADING" || orderDetail?.eventAlert?.eventCategorizationId?.eventType === "TRANSACTIONS_FAILURES" ? "(%)" : ""
                                                                            :
                                                                            ""}
                                                                    </>

                                                                </label>
                                                                <div className="security-detail-country">
                                                                    <p className="toekn-main-paragraph">{orderDetail?.eventAlert?.eventCategorizationId?.range || 'N/A'}</p>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Time</label>
                                                                <p className="toekn-main-paragraph">{orderDetail?.eventAlert?.eventCategorizationId?.time + " " + orderDetail?.eventAlert?.eventCategorizationId?.timeType || 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="wallet-info">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Status</label>
                                                                <p className="toekn-main-paragraph">
                                                                    {orderDetail?.eventAlert?.isRead ? (
                                                                        <span className="resolved-text">Resolved</span>
                                                                    ) : (
                                                                        <button
                                                                            onClick={() => handleMarkAsResolved(orderDetail?.eventAlert?._id, false)}
                                                                            className="mark-as-resolved-btn status-mark-as-resolved-btn"
                                                                        >
                                                                            Mark as Resolve
                                                                        </button>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            {(orderDetail?.eventAlert?.eventCategorizationId?.eventType === "API_KEY_USAGE" || orderDetail?.eventAlert?.eventCategorizationId?.eventType === "ABNORMAL_LOGIN") && (
                                                                <div className="col-md-6">
                                                                    <label>Wallet Address</label>

                                                                    {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "ABNORMAL_LOGIN" ? (
                                                                        <>
                                                                            <p className="toekn-main-paragraph under-wallet ">
                                                                                {orderDetail?.eventAlert?.userIds[0]?.walletAddress ? (
                                                                                    <CopyToClipboard
                                                                                        text={orderDetail?.eventAlert?.userIds[0]?.walletAddress}
                                                                                        onCopy={() => handleCopy(0, orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                    >
                                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            {formatWalletAddress(orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                            <FaCopy className="copy-icon" style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                                                            {copiedIndex === 0 && (
                                                                                                <span className="copied-text" style={{ marginLeft: '10px', color: 'green' }}>
                                                                                                    Copied!
                                                                                                </span>
                                                                                            )}
                                                                                        </span>
                                                                                    </CopyToClipboard>
                                                                                ) : (
                                                                                    "N/A"
                                                                                )}
                                                                            </p>
                                                                        </>
                                                                    ) : orderDetail?.eventAlert?.eventCategorizationId?.eventType === "API_KEY_USAGE" ? (
                                                                        <>
                                                                            <p className="toekn-main-paragraph under-wallet">
                                                                                {orderDetail?.eventAlert?.userIds[0]?.walletAddress ? (
                                                                                    <CopyToClipboard
                                                                                        text={orderDetail?.eventAlert?.userIds[0]?.walletAddress}
                                                                                        onCopy={() => handleCopy(1, orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                    >
                                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            {formatWalletAddress(orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                            <FaCopy className="copy-icon" style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                                                            {copiedIndex === 1 && (
                                                                                                <span className="copied-text" style={{ marginLeft: '10px', color: 'green' }}>
                                                                                                    Copied!
                                                                                                </span>
                                                                                            )}
                                                                                        </span>
                                                                                    </CopyToClipboard>
                                                                                ) : (
                                                                                    "N/A"
                                                                                )}
                                                                            </p>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            )}


                                                        </div>
                                                    </div>

                                                    {/* user detail */}
                                                    {orderDetail?.eventAlert?.userIds?.length > 0 &&
                                                        <div className="wallet-info">
                                                            <div className="row">
                                                                {/* rapid account */}
                                                                {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "RAPID_ACCOUNT" &&
                                                                    <div className="col-md-12">
                                                                        <label>User Detail</label>
                                                                        <div className="security-detail-table">
                                                                            <Table responsive="sm" className="UserListTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr No.</th>
                                                                                        <th className="security-deatil-wallet">Wallet Address</th>
                                                                                        <th>User IP</th>
                                                                                        <th>Geo Location(latitude,longitude)</th>
                                                                                        <th>Country</th>
                                                                                        <th>Created Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {orderDetail?.eventAlert?.userIds?.slice()?.reverse().map((user, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>


                                                                                            <td className="security-deatil-wallet under-wallet">
                                                                                                {user?.walletAddress ? (
                                                                                                    <CopyToClipboard
                                                                                                        text={user.walletAddress}
                                                                                                        onCopy={() => handleCopy(index, user.walletAddress)}
                                                                                                    >
                                                                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            {formatWalletAddress(user.walletAddress)}
                                                                                                            <FaCopy className="copy-icon" style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                                                                            {copiedIndex === index && (
                                                                                                                <span className="copied-text" style={{ marginLeft: '10px', color: 'green' }}>
                                                                                                                    Copied!
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </CopyToClipboard>
                                                                                                ) : (
                                                                                                    "N/A"
                                                                                                )}
                                                                                            </td>

                                                                                            <td>{user.ip || "N/A"}</td>
                                                                                            <td>
                                                                                                {user.latitude && user?.longitude ?
                                                                                                    <> {user.latitude} , {user?.longitude}</> : "N/A"}
                                                                                            </td>
                                                                                            <td>{user.country || "N/A"}</td>
                                                                                            <td>{user.createdAt ? moment(user?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>

                                                                    </div>}
                                                                {/* anormal login */}
                                                                {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "ABNORMAL_LOGIN" &&
                                                                    <div className="col-md-12">
                                                                        <label>User Detail</label>
                                                                        <div className="security-detail-table">
                                                                            <Table responsive="sm" className="UserListTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr No.</th>
                                                                                        {/* <th className="security-deatil-wallet">Wallet Address</th> */}
                                                                                        <th>Geo Location(latitude,longitude)</th>
                                                                                        <th>User IP</th>
                                                                                        <th>Timezone</th>
                                                                                        <th>Country</th>
                                                                                        <th>Created Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {orderDetail?.eventAlert?.userSessionIds?.slice().reverse()?.map((user, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {user.latitude} , {user?.longitude}
                                                                                            </td>
                                                                                            <td>{user.ip || "N/A"}</td>
                                                                                            <td>{user.timezone || "N/A"}</td>
                                                                                            <td>{user.country || "N/A"}</td>
                                                                                            <td>{user.createdAt ? moment(user?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>

                                                                    </div>}
                                                                {/* api key usage */}
                                                                {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "API_KEY_USAGE" &&
                                                                    <div className="col-md-12">
                                                                        <label>User Detail</label>
                                                                        <div className="security-detail-table">
                                                                            <Table responsive="sm" className="UserListTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr No.</th>
                                                                                        <th className="security-deatil-wallet">End Point</th>
                                                                                        {/* <th className="security-deatil-wallet">Wallet Address</th> */}
                                                                                        <th>Geo Location(latitude,longitude)</th>
                                                                                        <th>User IP</th>
                                                                                        <th>Method Name</th>
                                                                                        <th>Created Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        orderDetail?.eventAlert?.apiRequestIds?.slice()?.reverse()?.map((user, index) => (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>

                                                                                                <td className="security-deatil-wallet-text">
                                                                                                    {user.endpoint || "N/A"}
                                                                                                </td>

                                                                                                {/* <td className="security-deatil-wallet">
                                                                                                    {orderDetail?.eventAlert?.userIds[0]?.walletAddress ? (
                                                                                                        <CopyToClipboard
                                                                                                            text={orderDetail?.eventAlert?.userIds[0]?.walletAddress}
                                                                                                            onCopy={() => handleCopy(index, orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                                        >
                                                                                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                {formatWalletAddress(orderDetail?.eventAlert?.userIds[0]?.walletAddress)}
                                                                                                                <FaCopy className="copy-icon" style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                                                                                                {copiedIndex === index && (
                                                                                                                    <span className="copied-text" style={{ marginLeft: '10px', color: 'green' }}>
                                                                                                                        Copied!
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </span>
                                                                                                        </CopyToClipboard>
                                                                                                    ) : (
                                                                                                        "N/A"
                                                                                                    )}
                                                                                                </td> */}


                                                                                                <td>
                                                                                                    {user?.userSessionId?.latitude && user?.userSessionId?.longitude
                                                                                                        ? `${user.userSessionId.latitude}, ${user.userSessionId.longitude}`
                                                                                                        : "N/A"}
                                                                                                </td>

                                                                                                <td>{user?.userSessionId?.ip || "N/A"}</td>
                                                                                                <td>{user.method || "N/A"}</td>
                                                                                                <td>{user.createdAt ? moment(user?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>

                                                                    </div>}
                                                            </div>
                                                        </div>}

                                                    {/* transaction detail */}



                                                    {orderDetail?.eventAlert?.transactionIds?.length > 0 &&
                                                        <div className="wallet-info">
                                                            <div className="row">
                                                                {(orderDetail?.eventAlert?.eventCategorizationId?.eventType == "TRANSACTIONS_FAILURES") && (
                                                                    <div className="col-md-12">
                                                                        <label>Transaction Detail</label>
                                                                        <div className="security-detail-table">
                                                                            <Table responsive="sm" className="UserListTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr No.</th>
                                                                                        <th>From Token</th>
                                                                                        <th>From Amount</th>
                                                                                        <th>To Token</th>
                                                                                        <th>To Amount</th>
                                                                                        <th className="security-deatil-wallet">Transaction Type</th>
                                                                                        <th>Transaction Status</th>
                                                                                        <th>Transaction hash</th>
                                                                                        <th>Created Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {orderDetail?.eventAlert?.transactionIds?.slice()?.reverse()?.map((user, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>
                                                                                                    <div className="token-details">
                                                                                                        {user?.fromToken?.logoURI ?
                                                                                                            <img src={user?.fromToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} <span>{user?.fromToken?.symbol || "N/A"}
                                                                                                            <div className="d-flex">
                                                                                                                <OverLayAddressTooltip overlayValue={user?.fromToken?.address} defaultShow={true}>
                                                                                                                    <span
                                                                                                                        style={mouseHoverAddressValue == user?.fromToken?.address ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                                                                                                        onMouseEnter={() => setMouseHoverAddressValue(user?.fromToken?.address)}
                                                                                                                        onMouseLeave={() => setMouseHoverAddressValue('')}
                                                                                                                    >
                                                                                                                        {formatWalletAddress(user?.fromToken?.address) || "N/A"}
                                                                                                                    </span>
                                                                                                                </OverLayAddressTooltip>
                                                                                                                {user?.fromToken?.address && (
                                                                                                                    <>
                                                                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                                            <CopyToClipboard text={user?.fromToken?.address} onCopy={() => handleTransactionDataCopy(index, "fromToken")}>
                                                                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                                                                    <FaCopy />
                                                                                                                                </button>
                                                                                                                            </CopyToClipboard>
                                                                                                                        </OverLayAddressTooltip>
                                                                                                                        {copiedFromAddress && (copiedFromValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{user?.fromAmount ? parseFloat(user?.fromAmount).toPrecision(10) : 'N/A'}</td>
                                                                                                <td>
                                                                                                    <div className="token-details">
                                                                                                        {user?.toToken?.logoURI ?
                                                                                                            <img src={user?.toToken?.logoURI} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} <span>{user?.toToken?.symbol || "N/A"}
                                                                                                            <div className="d-flex">
                                                                                                                <OverLayAddressTooltip overlayValue={user?.toToken?.address} defaultShow={true}>
                                                                                                                    <span
                                                                                                                        style={mouseHoverAddressValue == user?.toToken?.address ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" } : { borderRadius: '5px', padding: "1px 2px" }}
                                                                                                                        onMouseEnter={() => setMouseHoverAddressValue(user?.toToken?.address)}
                                                                                                                        onMouseLeave={() => setMouseHoverAddressValue('')}
                                                                                                                    >
                                                                                                                        {formatWalletAddress(user?.toToken?.address) || "N/A"}
                                                                                                                    </span>
                                                                                                                </OverLayAddressTooltip>
                                                                                                                {user?.toToken?.address && (
                                                                                                                    <>
                                                                                                                        <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                                            <CopyToClipboard text={user?.toToken?.address} onCopy={() => handleTransactionDataCopy(index, "toAddress")}>
                                                                                                                                <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                                                                    <FaCopy />
                                                                                                                                </button>
                                                                                                                            </CopyToClipboard>
                                                                                                                        </OverLayAddressTooltip>
                                                                                                                        {copiedToAddress && (copiedToValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{user?.toAmount ? parseFloat(user?.toAmount).toPrecision(10) : 'N/A'}</td>
                                                                                                <td>
                                                                                                    {user.transactionType || "N/A"}
                                                                                                </td>
                                                                                                <td>{user.transactionStatus || "N/A"}</td>
                                                                                                <td>{formatWalletAddress(user?.transactionHash) || "N/A"}
                                                                                                    {user?.transactionHash && (
                                                                                                        <>
                                                                                                            <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                                <CopyToClipboard text={user?.transactionHash} onCopy={() => handleTransactionDataCopy(index, "hashAddress")}>
                                                                                                                    <button style={{ marginLeft: '8px', border: 'none', background: 'none', cursor: 'pointer' }}>
                                                                                                                        <FaCopy />
                                                                                                                    </button>
                                                                                                                </CopyToClipboard>
                                                                                                            </OverLayAddressTooltip>
                                                                                                            {copiedHash && (copiedHashValue === index) && <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>Copied!</span>}
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>{user.createdAt ? moment(user?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>

                                                                    </div>)}
                                                            </div>
                                                        </div>}

                                                    {/* High Order transactions */}
                                                    {console.log("orderDetail::::::", orderDetail)}
                                                    {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "HIGH_TRADING" && (
                                                        <div className="wallet-info">
                                                            <div className="row">
                                                                {orderDetail?.eventAlert?.eventCategorizationId?.eventType === "HIGH_TRADING" && (
                                                                    <div className="col-md-12">
                                                                        <label>Trading Token Detail</label>
                                                                        <div className="security-detail-table">
                                                                            <Table responsive="sm" className="UserListTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sr No.</th>
                                                                                        <th>Token data</th>
                                                                                        <th>Token Name</th>

                                                                                        <th>Created Time</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>1</td>
                                                                                        <td>
                                                                                            <div className="token-details">
                                                                                                {orderDetail?.eventAlert?.tokenId?.logoURI ? (
                                                                                                    <img
                                                                                                        src={orderDetail?.eventAlert?.tokenId?.logoURI}
                                                                                                        alt="Token Logo"
                                                                                                        height={50}
                                                                                                        width={50}
                                                                                                        className="rounded-circle"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img
                                                                                                        src={userImg}
                                                                                                        alt="Default Logo"
                                                                                                        height={50}
                                                                                                        width={50}
                                                                                                        className="rounded-circle"
                                                                                                    />
                                                                                                )}
                                                                                                <span>
                                                                                                    {orderDetail?.eventAlert?.tokenId?.symbol || "N/A"}
                                                                                                    <div className="d-flex">
                                                                                                        <OverLayAddressTooltip
                                                                                                            overlayValue={orderDetail?.eventAlert?.tokenId?.address}
                                                                                                            defaultShow={true}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={
                                                                                                                    mouseHoverAddressValue === orderDetail?.eventAlert?.tokenId?.address
                                                                                                                        ? { background: "#fbdf2538", borderRadius: '5px', padding: "1px 2px" }
                                                                                                                        : { borderRadius: '5px', padding: "1px 2px" }
                                                                                                                }
                                                                                                                onMouseEnter={() => setMouseHoverAddressValue(orderDetail?.eventAlert?.tokenId?.address)}
                                                                                                                onMouseLeave={() => setMouseHoverAddressValue('')}
                                                                                                            >
                                                                                                                {formatWalletAddress(orderDetail?.eventAlert?.tokenId?.address) || "N/A"}
                                                                                                            </span>
                                                                                                        </OverLayAddressTooltip>
                                                                                                        {orderDetail?.eventAlert?.tokenId?.address && (
                                                                                                            <>
                                                                                                                <OverLayAddressTooltip overlayValue={"Copy Address"}>
                                                                                                                    <CopyToClipboard
                                                                                                                        text={orderDetail?.eventAlert?.tokenId?.address}
                                                                                                                        onCopy={() => handleTransactionDataCopy(1, "fromToken")}
                                                                                                                    >
                                                                                                                        <button
                                                                                                                            style={{
                                                                                                                                marginLeft: '8px',
                                                                                                                                border: 'none',
                                                                                                                                background: 'none',
                                                                                                                                cursor: 'pointer'
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <FaCopy />
                                                                                                                        </button>
                                                                                                                    </CopyToClipboard>
                                                                                                                </OverLayAddressTooltip>
                                                                                                                {copiedFromAddress && copiedFromValue === 1 && (
                                                                                                                    <span className="error-text" style={{ marginLeft: '8px', color: '#62fc25' }}>
                                                                                                                        Copied!
                                                                                                                    </span>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{orderDetail?.eventAlert?.tokenId?.name || "N/A"}</td>


                                                                                        <td>{orderDetail?.eventAlert?.createdAt ? moment(orderDetail?.eventAlert?.createdAt).format("DD-MM-YYYY hh:mm A") : 'N/A'}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}


                                                </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};
