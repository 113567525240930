import { Col, Container, Row, Form, Table, Button, Modal, OverlayTrigger, Tooltip, FormControl } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import React, { useCallback, useEffect, useState } from 'react';
import { OverLayAddressTooltip } from '../../common/Functions';
import { pineappleDexService } from '../../service/api.service';
import swal from "sweetalert";
import { ShimmerThumbnail } from 'react-shimmer-effects';
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export const TokenManagement = () => {

    const [modalShow, setModalShow] = useState(false);
    const [existTokensList, setExistTokensList] = useState([]);
    const [topTokenCount, setTopTokenCount] = useState(0);
    const [selectedTokenDetail, setSelectedTokenDetail] = useState(null);
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [searchLoader, setSearchLoader] = useState(false);

    const [fetchNewToken, setFetchNewToken] = useState(0);
    const [tokenLoader, setTokenLoader] = useState(false);
    const [newTokenList, setNewTokenList] = useState([]);
    const [searchedTokenList, setSearchedTokenList] = useState([]);
    const [newSearch, setNewSearch] = useState("");
    const [newSearchLoader, setNewSearchLoader] = useState(false);

    const [page, setPage] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [updateLoader, setUpdateLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        getTokenList(page, search);
    }, []);

    useEffect(() => {
        fetchTokens();
    }, [fetchNewToken])

    const getTokenList = async (page, search) => {
        setLoader(true);
        try {
            const response = await pineappleDexService.getExistTokensList(page, search);
            setLoader(false);
            setSearchLoader(false);
            if (response?.status == 200) {
                let totalCount = response.data && response.data.totalTokens ? response.data.totalTokens : 0;
                let list = response.data && response.data.tokens ? response.data.tokens : [];
                setExistTokensList(list);
                setTotalItems(totalCount);
                setTopTokenCount(response?.data?.topTokenCount);
            } else {
                throw new Error('Failed to fetch data');
            }
        }
        catch (error) {
            setLoader(false);
            setSearchLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };

    const filterNewTokenList = (e) => {
        let value = e.target.value;
        setNewSearch(value);
        setNewSearchLoader(true);
        let list = newTokenList.filter((token) => token?.name?.toLowerCase().includes(value.toLowerCase()) || token?.symbol?.toLowerCase().includes(value.toLowerCase()) || token?.address?.toLowerCase().includes(value.toLowerCase()));
        setSearchedTokenList(list);
        setNewSearchLoader(false);
    }

    const fetchTokens = async () => {
        setNewSearch("");
        setTokenLoader(true);
        try {
            const response = await pineappleDexService.getLatestTokens();
            setTokenLoader(false);
            if (response?.status == 200) {
                setNewTokenList(response?.data?.tokens);
                setSearchedTokenList(response?.data?.tokens);
                setCurrentPage(1);
                if (fetchNewToken > 0) {
                    swal({ icon: 'success', text: 'New tokens fetched successfully', button: "OK" })
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setTokenLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    };

    const paginatedTokenList = searchedTokenList.slice((itemsPerPage * (currentPage - 1)), (currentPage * itemsPerPage));


    function handlePageChange1(pageNumber) {
        setCurrentPage(pageNumber);
    };

    function handlePageChange(pageNumber) {
        let page = pageNumber - 1
        setPage(page);
        setActivePage(pageNumber);
        getTokenList(pageNumber - 1, search);
    };


    const handleTokenEnableDisable = async (tokenId, action, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to ${action.toLowerCase()} this token!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + action.toLowerCase() + ' it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const params = {
                        "tokenId": tokenId,
                        "action": action                         // 'ENABLE', 'DISABLE'
                    }
                    const response = await pineappleDexService.tokenEnableDisable(params)
                    if (response.status === 200) {
                        setExistTokensList(prevState => {
                            const newState = [...prevState];
                            newState[index] = response?.data?.token;
                            return newState;
                        })
                        swal({ icon: 'success', text: 'Token ' + action.toLowerCase() + ' successfully', button: "OK" })
                    } else {
                        throw new Error('Failed to fetch data');
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                            localStorage.clear();
                            window.location.href = "/";
                        });
                    } else {
                        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                            () => {
                                console.log('OK button clicked after error alert');
                            }
                        );
                    }
                }
            }
        })
    };

    const handleTokenToggleTopToken = async (tokenId, action, index) => {

        if (topTokenCount == 10) {
            Swal.fire({
                icon: "warning",
                // title: "Oops...",
                text: "You can’t add more than 10 tokens to the top list.",
            });
            return;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: action ? `You want to add this token to the top list!` : `You want to remove this token from top list!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${action ? "add" : "remove"} it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const params = {
                        "tokenId": tokenId,
                    }
                    const response = await pineappleDexService.handleTokenToggleTopToken(params)
                    if (response.status === 200) {
                        setExistTokensList(prevState => {
                            const newState = [...prevState];
                            newState[index] = response?.data?.token;
                            return newState;
                        })
                        setTopTokenCount(response?.data?.topTokenCount);
                        swal({ icon: 'success', text: response?.data.message, button: "OK" })
                    } else {
                        throw new Error('Failed to fetch data');
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                            localStorage.clear();
                            window.location.href = "/";
                        });
                    } else {
                        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                            () => {
                                console.log('OK button clicked after error alert');
                            }
                        );
                    }
                }
            }
        })
    };

    const handleCreateToken = async (data, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to add this token!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    delete data.tags
                    const response = await pineappleDexService.createToken(data)
                    if (response.status === 201) {
                        getTokenList(0, "");
                        setPage(0);
                        setActivePage(1);
                        setSearch("");
                        setNewTokenList((newTokenList) => newTokenList.filter((d) => d.address !== data.address))
                        setSearchedTokenList((newTokenList) => newTokenList.filter((_, i) => i !== index))
                        swal({ icon: 'success', text: 'Token added successfully', button: "OK" })
                    } else {
                        throw new Error('Failed to fetch data');
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                            localStorage.clear();
                            window.location.href = "/";
                        });
                    } else {
                        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                            () => {
                                console.log('OK button clicked after error alert');
                            }
                        );
                    }
                }
            }
        })
    };

    const handleDeleteToken = async (data, index, type = "new") => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to delete this token!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    delete data.tags
                    const response = await pineappleDexService.deleteLatestToken(data)
                    if (response.status === 200) {
                        if (type === "new") {
                            setNewTokenList((newTokenList) => newTokenList.filter((d) => d.address !== data.address))
                            setSearchedTokenList((newTokenList) => newTokenList.filter((_, i) => i !== index))
                        } else {
                            getTokenList(0, "");
                            setPage(0);
                            setActivePage(1);
                            setSearch("");
                        }
                        swal({ icon: 'success', text: 'Token deleted successfully', button: "OK" })
                    } else {
                        throw new Error('Failed to fetch data');
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                            localStorage.clear();
                            window.location.href = "/";
                        });
                    } else {
                        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                            () => {
                                console.log('OK button clicked after error alert');
                            }
                        );
                    }
                }
            }
        })
    };

    const editToken = async (data) => {
        setSelectedTokenDetail(data)
        setModalShow(true)
    }

    const handleIconChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        setSelectedTokenDetail({ ...selectedTokenDetail, logoFile: file })
    }

    const updateToken = async () => {
        try {
            setUpdateLoader(true);
            const formData = new FormData();
            formData.append('symbol', selectedTokenDetail.symbol.trimEnd());
            formData.append('name', selectedTokenDetail.name.trimEnd());
            if (selectedTokenDetail.logoFile) {
                formData.append('tokenLogo', selectedTokenDetail.logoFile);
            }
            const response = await pineappleDexService.updateToken(selectedTokenDetail._id, formData);
            setUpdateLoader(false);
            if (response.status === 200) {
                setModalShow(false)
                setExistTokensList(existTokensList.map((token) => token._id === selectedTokenDetail._id ? response.data.token : token))
                swal({ icon: 'success', text: 'Token updated successfully', button: "OK" })
            } else {
                throw new Error('Failed to fetch data');
            }

        } catch (error) {
            setUpdateLoader(false);
            if (error?.response?.status == 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    () => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }

    return (
        <>
            <div className="dashboard-main-area">
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12}>
                                <Row className="justify-content-between">
                                    <Col md={9} className="d-flex ">
                                        <h2 className="align-self-center m-0">Token Management</h2>
                                    </Col>

                                    <Col md={3} className="search-area text-right">
                                        <Button className='fetch-token' onClick={() => setFetchNewToken((prev) => prev + 1)}>
                                            <i className="fa-solid fa-retweet"></i> Fetch token
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* token management table */}
                        <Row className="pb-4 justify-content-center">
                            <Col md={6}>
                                <div className='token-management-right'>
                                    <h3 className="subheading-text">Existing token  →</h3>
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            value={search}
                                            className="form-control inner-input"
                                            placeholder="Token address, name and symbol..."
                                            onChange={(e) => {
                                                setSearchLoader(true);
                                                setSearch(e.target.value);
                                                getTokenList(0, e.target.value);
                                                setPage(0)
                                                setActivePage(1)
                                            }}
                                            onKeyDown={(e) => {
                                                if (!e.target.value && e.code === "Space") {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {
                                            searchLoader ?
                                                <span className="loader-search"></span>
                                                :
                                                <>
                                                    <i
                                                        className="fa fa-search"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <i className="fa fa-close d-none" aria-hidden="true"></i>
                                                </>
                                        }
                                    </div>
                                </div>


                                <div className="audit-request-box toeken-table-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Icon with text</th>
                                                <th>Full name</th>
                                                <th>Enable/Disable</th>
                                                <th>Add/Remove Top token</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loader ?
                                                (
                                                    <>
                                                        {[...Array(10)].map((_, index) => (
                                                            <tr key={index}>
                                                                {[...Array(4)].map((_, i) => <td key={i}><ShimmerThumbnail /></td>)}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                existTokensList && existTokensList?.length > 0 ?
                                                    existTokensList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className='display-token'>
                                                                    {item.logoURI ?
                                                                        <img className='under-token-img'
                                                                            src={item.logoURI}
                                                                            alt="" /> :
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                fontSize: '20px',
                                                                                color: 'rgb(255, 255, 255)',
                                                                                marginRight: '5px',
                                                                                width: '40px',
                                                                                backgroundColor: '#fce02570',
                                                                                padding: '4px',
                                                                                borderRadius: '50%',
                                                                                minWidth: '40px',
                                                                                height: '40px',
                                                                                minHeight: '40px',
                                                                                fontWeight: '700',
                                                                                border: '4px solid #1f2125',
                                                                            }}
                                                                        >
                                                                            {item.name?.charAt(0)?.toUpperCase() || 'N/A'}
                                                                        </div>}

                                                                    <span className='WETH-text'>{item.symbol}</span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.name}
                                                            </td>
                                                            <td>
                                                                <OverLayAddressTooltip overlayValue={item.status === 'ENABLE' ? 'Disable' : 'Enable'}>
                                                                    <Form.Check className='custom-enable-disable mb-0 pb-0'
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label=""
                                                                        checked={item.status === 'ENABLE' ? true : false}
                                                                        onChange={() => handleTokenEnableDisable(item._id, item.status === 'ENABLE' ? 'DISABLE' : 'ENABLE', index)}
                                                                    />
                                                                </OverLayAddressTooltip>
                                                            </td>

                                                            <td>
                                                                <OverLayAddressTooltip overlayValue={item.isTop ? 'Remove from top list' : 'Add to top list'}>
                                                                    <Form.Check className='custom-enable-disable mb-0 pb-0'
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label=""
                                                                        checked={item.isTop ? true : false}
                                                                        onChange={() => handleTokenToggleTopToken(item._id, !item.isTop, index)}
                                                                    />
                                                                </OverLayAddressTooltip>
                                                            </td>
                                                            <td>
                                                                <div className='action-btn d-flex align-items-center'>
                                                                    <Link to={`/token/${item._id}`} state={{ item: item }}>
                                                                        <OverLayAddressTooltip overlayValue={"View"}>
                                                                            <div role='button' className="view-btn-main me-2">
                                                                                <i className="fa-solid fa-eye"></i>
                                                                            </div>
                                                                        </OverLayAddressTooltip>
                                                                    </Link>
                                                                    <OverLayAddressTooltip overlayValue={'Edit'}>
                                                                        <div role='button' className="view-btn-main me-2" onClick={() => editToken(item)}>
                                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                                        </div>
                                                                    </OverLayAddressTooltip>
                                                                    <OverLayAddressTooltip overlayValue={"Delete token"}>
                                                                        <div role='button' className="view-btn-main" onClick={() => handleDeleteToken(item, index, "existing")}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </div>
                                                                    </OverLayAddressTooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr className="no-dat-found-box">
                                                        <td colSpan={5}>
                                                            <img
                                                                src={
                                                                    require("../../assets/images/no-data.svg")
                                                                        .default
                                                                }
                                                                alt="da"
                                                            />
                                                            <h3 className="text-center text-light">
                                                                No Data found
                                                            </h3>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {existTokensList.length > 0 && totalItems > 10 ?
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='token-management-right'>
                                    <h3 className="subheading-text">New token  →</h3>
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            value={newSearch}
                                            className="form-control inner-input"
                                            placeholder="Token address, name and symbol..."
                                            onChange={(e) =>
                                                filterNewTokenList(e)
                                            }
                                            onKeyDown={(e) => {
                                                if (!e.target.value && e.code === "Space") {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                        {
                                            newSearchLoader ?
                                                <span className="loader-search"></span>
                                                :
                                                <>
                                                    <i
                                                        className="fa fa-search"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <i className="fa fa-close d-none" aria-hidden="true"></i>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="audit-request-box under-table-main">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Icon with text</th>
                                                <th>Full name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tokenLoader ?
                                                (
                                                    <>
                                                        {[...Array(15)].map((_, index) => (
                                                            <tr key={index}>
                                                                {[...Array(3)].map((_, i) => <td key={i}><ShimmerThumbnail /></td>)}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                paginatedTokenList && paginatedTokenList?.length > 0 ?
                                                    paginatedTokenList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span className='display-token'>
                                                                    {item.logoURI ?
                                                                        <img className='under-token-img'
                                                                            src={item.logoURI}
                                                                            alt="" /> :
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                fontSize: '20px',
                                                                                color: 'rgb(255, 255, 255)',
                                                                                marginRight: '5px',
                                                                                width: '40px',
                                                                                backgroundColor: '#fce02570',
                                                                                padding: '4px',
                                                                                borderRadius: '50%',
                                                                                minWidth: '40px',
                                                                                height: '40px',
                                                                                minHeight: '40px',
                                                                                fontWeight: '700',
                                                                                border: '4px solid #1f2125',
                                                                            }}
                                                                        >
                                                                            {item.name?.charAt(0)?.toUpperCase() || 'N/A'}
                                                                        </div>}
                                                                    <span className='WETH-text'>{item.symbol}</span>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.name}
                                                            </td>
                                                            <td className='d-flex'>
                                                                <OverLayAddressTooltip overlayValue={"Add token"}>
                                                                    <div role='button' className="view-btn-main me-2" onClick={() => handleCreateToken(item, index)}>
                                                                        <i className="fa-solid fa-plus"></i>
                                                                    </div>
                                                                </OverLayAddressTooltip>
                                                                <OverLayAddressTooltip overlayValue={"Delete token"}>
                                                                    <div role='button' className="view-btn-main" onClick={() => handleDeleteToken(item, index)}>
                                                                        <i className="fa-solid fa-trash"></i>
                                                                    </div>
                                                                </OverLayAddressTooltip>
                                                            </td>
                                                        </tr>
                                                    )) :
                                                    <tr className="no-dat-found-box">
                                                        <td colSpan={5}>
                                                            <img
                                                                src={
                                                                    require("../../assets/images/no-data.svg")
                                                                        .default
                                                                }
                                                                alt="da"
                                                            />
                                                            <h3 className="text-center text-light">
                                                                No Data found
                                                            </h3>
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>

                                    </Table>
                                    <div className="new-tokens-pagination">
                                        {searchedTokenList.length > 0 && searchedTokenList.length > 10 ?
                                            <Pagination
                                                activePage={currentPage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={searchedTokenList.length}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange1(e)}
                                                prevPageText={"Prev"}
                                                nextPageText={"Next"}
                                            />
                                            : null

                                        }</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Modal
                    className="add-new-device-popup"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Token
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='border-0'>
                        <div className='add-new-device-form'>
                            {console.log("selectedTokenDetail", selectedTokenDetail)}
                            <Form>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className='form-label'>Icon</span>
                                            </Form.Label>
                                            <div className='edit-icon-area'>
                                                {selectedTokenDetail?.logoURI || selectedTokenDetail?.logoFile ?
                                                    <img className='under-token-img'
                                                        src={selectedTokenDetail?.logoFile ? URL.createObjectURL(selectedTokenDetail?.logoFile) : selectedTokenDetail?.logoURI ? selectedTokenDetail?.logoURI : ""
                                                        }
                                                        alt="" /> :
                                                    <div className='text-name'
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontSize: '20px',
                                                            color: 'rgb(255, 255, 255)',
                                                            marginRight: '5px',
                                                            width: '40px',
                                                            backgroundColor: '#fce02570',
                                                            padding: '4px',
                                                            borderRadius: '50%',
                                                            minWidth: '40px',
                                                            height: '40px',
                                                            minHeight: '40px',
                                                            fontWeight: '700',
                                                            border: '4px solid #1f2125',
                                                        }}
                                                    >
                                                        {selectedTokenDetail?.name?.charAt(0)?.toUpperCase() || 'N/A'}
                                                    </div>
                                                }
                                                <div className='edit-icon-outer'>
                                                    <div className='edit-icon-inner'>
                                                        <i class="fa-solid fa-pen"></i>
                                                        <FormControl type="file" accept="image/*" onChange={(e) => handleIconChange(e)} placeholder="Icon with text" />
                                                    </div>
                                                </div>

                                            </div>

                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className='form-label'>Symbol</span>
                                            </Form.Label>
                                            <FormControl type="text" placeholder="Symbol" value={selectedTokenDetail?.symbol} onChange={(e) => setSelectedTokenDetail({ ...selectedTokenDetail, symbol: e.target.value.trimStart() })} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                <span className='form-label'>Full name</span>
                                            </Form.Label>
                                            <FormControl type="text" placeholder="Full name" value={selectedTokenDetail?.name} onChange={(e) => setSelectedTokenDetail({ ...selectedTokenDetail, name: e.target.value.trimStart() })} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-0'>
                        <Button variant='unset' className='text-dark' onClick={() => updateToken()} disabled={updateLoader}>Update</Button>
                        <Button className='text-dark edit-close' onClick={() => setModalShow(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
